export default [
  {
    header: 'รายงาน',
  },
  {
    title: 'รายงานสมาชิก',
    route: 'report-member',
    icon: 'UsersIcon',
  },
  {
    title: 'รายงาน VIP',
    route: 'report-vip',
    icon: 'UsersIcon',
  },
  {
    title: 'การจ่ายโปรโมชั่น',
    route: 'report-paidpromotion',
    icon: 'GiftIcon',
  },
  {
    title: 'รายงานแพ้ชนะ',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'สรุปรายสมาชิก',
        route: 'report-user-winloss',
      },
      {
        title: 'สรุปรายค่ายเกมส์',
        route: 'report-gameprovider-winloss',
      },
    ],
  },
  {
    title: 'รายงานสรุปรายวัน',
    route: 'report-daily-summary',
    icon: 'CalendarIcon',
  },
  {
    title: 'รายงานสรุปบัญชี',
    route: 'report-account-summary',
    icon: 'BarChartIcon',
  },
]
