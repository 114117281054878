export default [
  {
    header: 'ตั้งค่า',
  },
  {
    title: 'จัดการแอดมิน',
    route: 'manage-admin',
    icon: 'UserIcon',
  },
  {
    title: 'ป๊อปอัพหน้าเว็บ',
    route: 'manage-popups',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'กลุ่มลูกค้า',
    route: 'manage-member-group',
    icon: 'UsersIcon',
  },
  // {
  //   title: 'ทางเข้าค่ายเกมส์',
  //   route: 'manage-gameprovider',
  //   icon: 'SettingsIcon',
  // },
  {
    title: 'โปรโมชั่น',
    route: 'setting-promotion',
    icon: 'GiftIcon',
  },
  {
    title: 'ระบบ',
    route: 'setting-system',
    icon: 'SettingsIcon',
  },
]
